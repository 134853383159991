import React from "react"

import s from "./Pagination.module.scss"

import Arrow from "../../../../images/arrow.inline.svg"

export const Pagination = ({ className, onPrev, onNext }) => {
  return (
    <div className={`${s.Wrapper} ${className && className}`}>
      <div className={s.Pagination}>
        <div className={s.Pagination__prev} onClick={onNext && onNext}>
          <Arrow />
        </div>
        <div className={s.Pagination__next} onClick={onPrev && onPrev}>
          <Arrow />
        </div>
      </div>
    </div>
  )
}
