import React, { useState, useEffect } from "react"

import { useSelector, useDispatch } from "react-redux"
import { navigate } from "gatsby"
import { Animate, AnimateGroup } from "react-simple-animate"

import { backRound, getTeams, setState } from "../../../ducks/playSlice"
import { PlayWrapper } from "../PlayWrapper/PlayWrapper"
import { Bar } from "../Common/Bar/Bar"
import { Button } from "../../../components/common/button/Button"
import { Scores } from "../Common/Scores/Scores"

import s from "./Round.module.scss"

export const Round = ({ currentRound }) => {
  const dispatch = useDispatch()
  const rounds = useSelector(state => state.play.rounds)
  // const currentRound = useSelector(state => state.play.currentRound)
  const gameId = useSelector(state => state.play.gameId)
  let round = rounds[currentRound]
  let teams = useSelector(state => state.play.teams)

  const [showScores, setShowScores] = useState(true)

  const _continue = () => {
    navigate(`/play/question/${parseInt(currentRound)}/0`)
  }

  let showIntro = !showScores
  if (parseInt(currentRound) < 1) {
    showIntro = true
  }

  useEffect(() => {
    dispatch(
      setState({
        currentRound: parseInt(currentRound),
      })
    )
    if (typeof window !== `undefined`) {
      const checker = window.setInterval(() => {
        dispatch(
          getTeams({
            gameId: gameId,
          })
        )
      }, 3000)
      return function cleanup() {
        clearInterval(checker)
      }
    }
  }, [])

  return (
    <PlayWrapper showBack={false} hideMenu={true}>
      {!showIntro && (
        <div className={`${s.Inner} ${s.Inner__scores}`}>
          <Scores
            title={"The Scores"}
            className={s.Scores}
            teams={teams || []}
            intro={true}
            buttonText={`On to Round ${parseInt(currentRound) + 1}`}
            currentRound={parseInt(currentRound)}
            onClick={() => {
              setShowScores(false)
            }}
          />
        </div>
      )}

      {showIntro && (
        <div
          className={s.Wrapper}
          style={{
            backgroundColor: round.color,
          }}
        >
          <div className={s.Inner}>
            <div className={s.Meta}>
              <Animate
                play
                start={{ opacity: 0.0 }}
                end={{ opacity: 1 }}
                delay={0.8}
                duration={0.5}
                easeType="cubic-bezier(0.445, 0.05, 0.55, 0.95)"
              >
                <Bar
                  round={parseInt(currentRound)}
                  difficulty={round.difficulty}
                  number={round.questions.length}
                  color={round.color}
                />
                <h2>{round.title}</h2>
                <p>{round.blurb}</p>
                <Button className={s.Button} onClick={_continue}>
                  <span>Begin Round</span>
                </Button>
              </Animate>
            </div>
            <div className={s.Cover}>
              <Animate
                play
                start={{ opacity: 0.0 }}
                end={{ opacity: 1 }}
                delay={0.5}
                duration={0.5}
                easeType="cubic-bezier(0.445, 0.05, 0.55, 0.95)"
              >
                <img src={round.cover.fields.file.url} />
              </Animate>
            </div>
          </div>
        </div>
      )}
    </PlayWrapper>
  )
}
