import React from "react"

import s from "./Progress.module.scss"

export const Progress = ({ currentQuestion, rounds, currentRound }) => {
  return (
    <div className={s.Progress}>
      <div
        style={{
          width:
            ((currentQuestion + 1) / rounds[currentRound].questions.length) *
              100 +
            "%",
        }}
      />
    </div>
  )
}
