import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { navigate } from "gatsby"
import { loadGame, getTeams, setScore } from "../../../ducks/playSlice"

import s from "./Intro.module.scss"

import { Button } from "../../../components/common/button/Button"
import { PlayWrapper } from "../PlayWrapper/PlayWrapper"
import { Cards } from "./Cards"
import { Scores } from "../Common/Scores/Scores"

export const Intro = () => {
  const dispatch = useDispatch()
  const [showCards, setShowCards] = useState(false)
  const gameId = useSelector(state => state.play.gameId)

  const title = useSelector(state => state.play.title)
  let rounds = useSelector(state => state.play.rounds)
  let teams = useSelector(state => state.play.teams)

  rounds = rounds.filter(Boolean)
  const _continue = () => {
    navigate(`/play/round/0`)
  }

  return (
    <PlayWrapper showBack={true}>
      <div className={s.Wrapper}>
        {!showCards && (
          <Scores
            className={s.Scores}
            teams={teams || []}
            title={"The Challengers"}
            intro={true}
            buttonText={"Start Quiz"}
            onClick={() => {
              setShowCards(true)
            }}
          />
        )}
        {showCards && (
          <Cards
            _continue={_continue}
            rounds={rounds || []}
            title={title}
            className={s.Cards}
          />
        )}
      </div>
    </PlayWrapper>
  )
}
