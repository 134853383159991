import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"
import { useSelector, useDispatch } from "react-redux"
import { Link } from "gatsby"
import YouTube from "react-youtube"

import {
  nextAnswerQuestion,
  prevAnswerQuestion,
  setState,
  markAnswer,
} from "../../../ducks/playSlice"

import s from "./Answers.module.scss"

import { PlayWrapper } from "../PlayWrapper/PlayWrapper"
import { Bar } from "../Common/Bar/Bar"
import { Input } from "../../../components/common/input/Input"
import { Button } from "../../../components/common/button/Button"
import { Progress } from "../Common/Progress/Progress"

import Wrong from "../../../images/wrong.inline.svg"
import Right from "../../../images/right.inline.svg"

export const Answers = ({ currentRound, currentQuestion }) => {
  const dispatch = useDispatch()
  const gameId = useSelector(state => state.play.gameId)

  const [showResults, setShowResults] = useState(false)

  const rounds = useSelector(state => state.play.rounds)
  // const currentRound = useSelector(state => state.play.currentRound)
  // const currentQuestion = useSelector(state => state.play.currentQuestion)
  const end = useSelector(state => state.play.endOfAnswers)
  const question = rounds[currentRound].questions[currentQuestion]
  const questionImage = question.image
  const questionVideo = question.videoUrl
  const hasMedia = questionImage || questionVideo
  const isPresenter = useSelector(state => state.play.isPresenter)

  useEffect(() => {
    dispatch(
      setState({
        currentQuestion: 0,
      })
    )
  }, [])

  let parsedQuestion = parseInt(currentQuestion)
  let parsedRound = parseInt(currentRound)

  return (
    <PlayWrapper hideMenu={true} showBack={false}>
      <div
        className={s.Wrapper}
        style={
          !showResults
            ? {
                background: `url(${rounds[parsedRound].cover.fields.file.url})`,
              }
            : { background: rounds[parsedRound].color }
        }
      >
        {!showResults && (
          <div className={s.Intro}>
            <h4>Let’s see how you did…</h4>
            <Button
              onClick={() => {
                setShowResults(true)
              }}
            >
              <span>Check Answers</span>
            </Button>
          </div>
        )}
        {showResults && (
          <div
            className={`${s.Inner} ${
              questionImage && s.Inner___imageQuestion
            } ${questionVideo && s.Inner___videoQuestion}`}
          >
            <div className={s.BaseQuestion}>
              <Bar
                round={parsedRound}
                difficulty={rounds[parsedRound].difficulty}
                number={rounds[parsedRound].questions.length}
                current={parsedQuestion}
                color={rounds[parsedRound].color}
              />
              <h2>{question.question}</h2>
              <div className={s.Controls}>
                <Input
                  className={s.AnswerInput__answer}
                  type={"answer"}
                  label={"Correct Answer"}
                  value={question.answer}
                  disabled
                />

                {!isPresenter && (
                  <Input
                    className={s.AnswerInput}
                    type={"userAnswer"}
                    label={"Your Answer"}
                    value={question.userAnswer}
                    disabled
                  />
                )}

                <div className={s.Marking}>
                  <div
                    className={s.Marking__wrong}
                    onClick={() => {
                      dispatch(
                        markAnswer({
                          questionNumber: parsedQuestion,
                          round: parsedRound,
                          result: false,
                        })
                      )
                      dispatch(
                        nextAnswerQuestion(
                          parsedQuestion,
                          rounds[parsedRound].questions.length,
                          parsedRound,
                          rounds.length
                        )
                      )
                    }}
                  >
                    <Wrong />
                  </div>
                  <div
                    className={s.Marking__correct}
                    onClick={() => {
                      dispatch(
                        markAnswer({
                          questionNumber: parsedQuestion,
                          round: parsedRound,
                          result: true,
                        })
                      )

                      dispatch(
                        nextAnswerQuestion(
                          parsedQuestion,
                          rounds[parsedRound].questions.length,
                          parsedRound,
                          rounds.length
                        )
                      )
                    }}
                  >
                    <Right />
                  </div>
                </div>
              </div>
            </div>
            {hasMedia && (
              <div className={s.MediaQuesiton}>
                {questionImage && (
                  <img src={questionImage.fields.file.url} alt="" />
                )}
                {questionVideo && (
                  <YouTube
                    videoId={questionVideo}
                    containerClassName={"youtube"}
                  />
                )}
              </div>
            )}
          </div>
        )}
      </div>
      {showResults && (
        <Progress
          currentQuestion={parsedQuestion}
          currentRound={currentRound}
          rounds={rounds}
        />
      )}
    </PlayWrapper>
  )
}
