import React, { useEffect, useState } from "react"
import CookieConsent from "react-cookie-consent"

import s from "./PlayWrapper.module.scss"
// import Logo1x from "../../../images/Logo-White/Logo-White.png"
// import Logo2x from "../../../images/Logo-White/Logo-White@2x.png"
import T from "../../../images/T.png"
import empty from "../../../images/epmty.png"
import { AgeGate } from "../../../components/main/age-gate/AgeGate"
import { Header } from "../../../components/main/header/Header"

export const PlayWrapper = ({ children, showBack, hideMenu, inGame }) => {
  const [showAgeGate, setShowAgeGate] = useState(false)
  useEffect(() => {
    if (typeof window === "object") {
      setShowAgeGate(window.localStorage.getItem("18") ? false : true)
    }
  }, [])

  return (
    <div className={s.Wrapper}>
      <Header
        inGame={true}
        showBack={showBack}
        hideMenu={hideMenu}
        inGame={inGame}
      />
      {/* <header className={s.Header}>
        <div>
          <img
            className={s.Header__logo}
            srcset={`${empty} 2x, ${empty} 1x`}
            src={empty}
          />
        </div>
        <div>
          <img src={T} className={s.T} />
        </div>
      </header> */}
      {children}

      {/* <div className={s.MobileWarning}>
        <div className={s.MobileWarning__inner}>
          <p>
            Please view this quiz on a Desktop or Laptop for the best experience
          </p>
        </div>
      </div> */}
      <CookieConsent
        buttonText="I Accept"
        buttonStyle={{ backround: "#ffc72c", fontSize: "16px" }}
      >
        This website uses cookies to enhance the user experience.
        <a
          style={{ color: "#eaeaea", marginLeft: 10, fontSize: 16 }}
          href="https://tennents.co.uk/privacy-policy/"
          target="_blank"
        >
          Privacy Policy
        </a>
        <a
          style={{ color: "#eaeaea", marginLeft: 10, fontSize: 16 }}
          href="https://tennents.co.uk/terms-and-conditions/"
          target="_blank"
        >
          Terms and Conditions
        </a>
      </CookieConsent>
      {showAgeGate && <AgeGate close={() => setShowAgeGate(false)} />}
    </div>
  )
}
