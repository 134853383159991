import React from "react"
import { Router } from "@reach/router"
import { Provider } from "react-redux"
import { store } from "../store"
import { Link, graphql } from "gatsby"

// import { Play } from "../screens/Play/Play"
import { Intro } from "../screens/Play/Intro/Intro"
import { TeamName } from "../screens/Play/TeamName/teamName"
import { Round } from "../screens/Play/Round/Round"
import { Question } from "../screens/Play/Question/Question"
import { Answers } from "../screens/Play/Answers/Answers"
import { Results } from "../screens/Play/Results/Results"

const App = ({ data }) => {
  return (
    <Provider store={store}>
      <Router basepath="/play">
        <Results path="/results" />
        <Round path="/round/:currentRound" />
        <Question path="/question/:currentRound/:currentQuestion" />
        <Answers path="/answers/:currentRound/:currentQuestion" />
        <Intro path="/intro" />
        <TeamName path="/:gameId" />
        <TeamName path="/:gameId/:presenter" />
      </Router>
    </Provider>
  )
}
export default App
