import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { loadGame, setState, createTeam } from "../../../ducks/playSlice"
import { navigate } from "gatsby"
import { Interstill } from "../../../components/main/Interstil/Interstill"
import { words } from "../../../words"

import { PlayWrapper } from "../PlayWrapper/PlayWrapper"
import { Input } from "../../../components/common/input/Input"
import { Button } from "../../../components/common/button/Button"

import Bottles from "../../../images/light.png"

import s from "./TeamName.module.scss"

export const TeamName = ({ gameId, presenter }) => {
  const dispatch = useDispatch()
  const teamName = useSelector(state => state.play.teamName)
  const [error, setError] = useState(null)
  const [sending, setSending] = useState(false)
  const [showIntro, setShowIntro] = useState(true)
  const title = useSelector(state => state.play.title)

  useEffect(() => {
    if (!gameId) {
      return navigate(`/`)
    }
    dispatch(loadGame(gameId))

    if (presenter) {
      dispatch(
        setState({
          isPresenter: true,
        })
      )
      navigate(`/play/intro`)
    } else {
      dispatch(
        setState({
          isPresenter: false,
        })
      )
    }
  }, [])

  return (
    <PlayWrapper>
      {showIntro && (
        <Interstill
          close={() => {
            setShowIntro(false)
          }}
        >
          <div className={s.IntroWrapper}>
            <h3>Let’s Begin</h3>
            <p>{title}</p>
          </div>
        </Interstill>
      )}
      <div
        className={s.Wrapper}
        style={{
          backgroundImage: `url(${Bottles})`,
        }}
      >
        <div className={s.Inner}>
          <p>Before we begin...</p>
          <h2>What is your team name?</h2>
          <Input
            className={s.Input}
            label={"Team Name"}
            value={teamName}
            onChange={e => {
              dispatch(
                setState({
                  teamName: e,
                })
              )
            }}
          />
          <Button
            className={s.Button}
            onClick={() => {
              if (teamName) {
                if (words.some(v => teamName.includes(v))) {
                  alert("Sorry, we can't accept your team name")
                  return
                }
                setError(null)
                if (!sending) {
                  setSending(true)
                  dispatch(
                    createTeam({
                      name: teamName,
                      gameId: gameId,
                    })
                  )
                }
              } else {
                setError("Please enter a team name")
              }
            }}
          >
            <span>Begin Quiz</span>
          </Button>

          <p className={s.Error}>{error}</p>
        </div>
      </div>
    </PlayWrapper>
  )
}
