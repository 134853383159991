import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"

import s from "./Results.module.scss"

import { PlayWrapper } from "../PlayWrapper/PlayWrapper"
import { Button } from "../../../components/common/button/Button"

import { loadGame, getTeams, setScore } from "../../../ducks/playSlice"

import Cup from "../../../images/cup.svg"

export const Results = () => {
  const gameId = useSelector(state => state.play.gameId)
  const play = useSelector(state => state.play)
  const teams = useSelector(state => state.play.teams)
  const isPresenter = useSelector(state => state.play.isPresenter)
  const dispatch = useDispatch()

  useEffect(() => {
    //calcualte round scores and submit
    if (isPresenter) {
      return
    }
    let total = play.rounds[play.rounds.length - 1].questions.filter(
      question => {
        return question.result == true
      }
    )
    dispatch(
      setScore({
        data: {
          round: play.rounds.length,
          score: total.length,
          id: play.teamId,
        },
        gameId: gameId,
      })
    )
  }, [])

  useEffect(() => {
    dispatch(
      getTeams({
        gameId: gameId,
      })
    )
    if (typeof window !== `undefined`) {
      const checker = window.setInterval(() => {
        dispatch(
          getTeams({
            gameId: gameId,
          })
        )
      }, 3000)
      return function cleanup() {
        clearInterval(checker)
      }
    }
  }, [])

  function ordinal_suffix_of(i) {
    var j = i % 10,
      k = i % 100
    if (j == 1 && k != 11) {
      return i + "st"
    }
    if (j == 2 && k != 12) {
      return i + "nd"
    }
    if (j == 3 && k != 13) {
      return i + "rd"
    }
    return i + "th"
  }

  return (
    <PlayWrapper>
      <div className={s.Wrapper}>
        <div className={s.Inner}>
          <div className={s.TopBar}>
            <div className={s.TopBar__winner}>
              {teams.length > 0 && (
                <>
                  <p>{teams[0].name}</p>
                  <div className={s.TopBar__points}>
                    <h4>{teams[0].score}</h4>
                    <span>pts</span>
                  </div>
                </>
              )}
            </div>
            <h2>Winner Winner</h2>
            <img src={Cup} alt="" />
          </div>
          {teams.length > 0 && (
            <div className={s.LeaderBoard}>
              {teams.map((team, i) => {
                return (
                  <div className={s.LeaderBoard__team}>
                    <div className={s.LeaderBoard__pos}>
                      {/* {i < teams.length - 1
                        ? ordinal_suffix_of(i + 1)
                        : "Wooden Spoon"} */}
                      {ordinal_suffix_of(i + 1)}
                    </div>
                    <h4 className={s.LeaderBoard__name}>{team.name}</h4>
                    {team.scores.length == play.rounds.length ? (
                      <div className={s.LeaderBoard__points}>
                        <div>
                          <h4>{team.score}</h4>
                          <span>pts</span>
                        </div>
                      </div>
                    ) : (
                      <div className={s.LeaderBoard__points}>
                        <span>playing....</span>
                      </div>
                    )}
                  </div>
                )
              })}
            </div>
          )}

          <div className={s.Buttons}>
            <Button
              onClick={() => {
                window.location.href = "https://pintless.tennents.co.uk/start"
              }}
            >
              <span>Create New Quiz</span>
            </Button>
            {/* <Button>
              <span>Share Results</span>
            </Button> */}
          </div>
        </div>
      </div>
    </PlayWrapper>
  )
}
