import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { navigate } from "gatsby"
import { Animate, useAnimate } from "react-simple-animate"
import YouTube from "react-youtube"

import { nextQuestion, prevQuestion } from "../../../ducks/playSlice"

import s from "./Question.module.scss"

import { PlayWrapper } from "../PlayWrapper/PlayWrapper"
import { Bar } from "../Common/Bar/Bar"
import { Input } from "../../../components/common/input/Input"
import { Pagination } from "../Common/Pagination/Pagination"
import { Progress } from "../Common/Progress/Progress"

export const Question = ({ currentRound, currentQuestion }) => {
  const dispatch = useDispatch()
  const rounds = useSelector(state => state.play.rounds)
  // const currentRound = useSelector(state => state.play.currentRound)
  // const currentQuestion = useSelector(state => state.play.currentQuestion)
  const isPresenter = useSelector(state => state.play.isPresenter)
  const question = rounds[currentRound].questions[currentQuestion]
  const [answer, setAnswer] = useState(question.userAnswer)
  const questionImage = question.image
  const questionVideo = question.videoUrl
  const hasMedia = questionImage || questionVideo

  useEffect(() => {
    setAnswer(question.userAnswer || "")
  }, [question])

  let parsedQuestion = parseInt(currentQuestion)
  let parsedRound = parseInt(currentRound)

  return (
    <PlayWrapper hideMenu={true}>
      <div
        className={s.Wrapper}
        style={{ background: rounds[parsedRound].color }}
      >
        <div
          className={`${s.Inner} ${questionImage && s.Inner___imageQuestion} ${
            questionVideo && s.Inner___videoQuestion
          }`}
        >
          <div className={s.BaseQuestion}>
            <Bar
              round={parsedRound}
              difficulty={rounds[parsedRound].difficulty}
              number={rounds[parsedRound].questions.length}
              current={parsedQuestion}
              color={rounds[parsedRound].color}
            />
            <h2>{question.question}</h2>
            <div className={s.Controls}>
              {!isPresenter && (
                <Input
                  className={s.AnswerInput}
                  onChange={val => setAnswer(val)}
                  label={"Your Answer"}
                  value={answer}
                />
              )}
              <Pagination
                className={`${s.Controls__pagination} ${
                  parsedQuestion < 1 && s.Controls__pagination___first
                }`}
                onNext={() => {
                  dispatch(
                    prevQuestion(
                      parsedQuestion,
                      rounds[parsedRound].questions.length,
                      parsedRound,
                      answer
                    )
                  )
                }}
                onPrev={() => {
                  dispatch(
                    nextQuestion(
                      parsedQuestion,
                      rounds[parsedRound].questions.length,
                      parsedRound,
                      rounds.length,
                      answer
                    )
                  )
                }}
              />
            </div>
          </div>
          {hasMedia && (
            <div className={s.MediaQuesiton}>
              {questionImage && (
                <img src={questionImage.fields.file.url} alt="" />
              )}
              {questionVideo && (
                <YouTube
                  videoId={questionVideo}
                  containerClassName={"youtube"}
                />
              )}
            </div>
          )}
        </div>
      </div>
      <Progress
        currentQuestion={parsedQuestion}
        currentRound={parsedRound}
        rounds={rounds}
      />
    </PlayWrapper>
  )
}
