import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"

import { loadGame, getTeams, setScore } from "../../../../ducks/playSlice"

import s from "./Scores.module.scss"

import Chev from "../../../../images/chev.svg"

import { Button } from "../../../../components/common/button/Button"

export const Scores = ({
  className,
  teams,
  buttonText,
  title,
  intro,
  onClick,
  currentRound,
}) => {
  const gameId = useSelector(state => state.play.gameId)
  const play = useSelector(state => state.play)
  const dispatch = useDispatch()
  const isPresenter = useSelector(state => state.play.isPresenter)

  useEffect(() => {
    //calcualte round scores and submit

    if (currentRound > 0 && !isPresenter) {
      let total = play.rounds[currentRound - 1].questions.filter(question => {
        return question.result == true
      })
      // let answers = play.rounds[currentRound - 1].questions.map(question => {
      //   return question.userAnswer
      // })
      dispatch(
        setScore({
          data: {
            round: currentRound - 1,
            score: total.length,
            id: play.teamId,
            // answers: answers,
          },
          gameId: gameId,
        })
      )
    }
  }, [])

  useEffect(() => {
    dispatch(
      getTeams({
        gameId: gameId,
      })
    )
    if (typeof window !== `undefined`) {
      const checker = window.setInterval(() => {
        dispatch(
          getTeams({
            gameId: gameId,
          })
        )
      }, 3000)
      return function cleanup() {
        clearInterval(checker)
      }
    }
  }, [])
  return (
    <div className={`${s.Wrapper} ${className}`}>
      <div className={s.Scores}>
        <div
          className={s.Scores__title}
          style={{
            backgroundImage: `url(${Chev})`,
          }}
        >
          <div className={s.Scores__title__inner}>
            <h3>{title}</h3>
          </div>
        </div>
        <div className={s.Scores__list}>
          <div className={s.Scores__listInner}>
            {teams.map(team => {
              return (
                <div className={s.Team}>
                  <h5>{team.name}</h5>

                  {play.currentRound > 0 && (
                    <div className={s.Team__points}>
                      {team.scores.length < play.currentRound ? (
                        <span>Loading...</span>
                      ) : (
                        <>
                          <h4>{team.score}</h4>
                          <span>pts</span>
                        </>
                      )}
                    </div>
                  )}
                </div>
              )
            })}
            <Button className={s.Button} onClick={onClick}>
              <span>{buttonText}</span>
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
