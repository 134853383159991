import React from "react"

import s from "./Bar.module.scss"

export const Bar = ({ round, difficulty, number, color, current }) => {
  return (
    <div className={s.Wrapper}>
      <div className={s.Bar}>
        <div className={s.Bar__round}>
          <span style={{ color: color }}>{round + 1}.</span>
        </div>
        <div className={s.Bar__difficulty}>
          <span>{difficulty}</span>
        </div>
        <div className={s.Bar__number}>
          {current == undefined && <span>{number} Questions</span>}
          {current !== undefined && (
            <span>
              Q {current + 1}/{number}
            </span>
          )}
        </div>
      </div>
    </div>
  )
}
